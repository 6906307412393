import React from 'react';
import homeImg from '../Assets/images/home-img.png';
import bgImg from '../Assets/images/bg-img.png';
import blobImg from '../Assets/images/blob-img.png';
import vanIcon from '../Assets/images/vanicon.png';
import About from './About';
import homePaw from '../Assets/images/home-paw.png';
import homeBone from '../Assets/images/home-bone.png';
import homePhone from '../Assets/images/Phone-home.png';

const Home = () => {
  return (
    <div>
      <div
        className="absolute top-0 right-0 w-[700px] h-[600px] bg-no-repeat bg-cover hidden md:block"
        style={{ backgroundImage: `url(${bgImg})` }}
      ></div>
      <div
        className="absolute -bottom-52 -left-[150px] w-[500px] h-[500px] bg-no-repeat bg-contain -z-10 hidden md:block"
        style={{ backgroundImage: `url(${blobImg})` }}
      ></div>
      <div
        className="absolute bottom-50 w-[600px] h-[700px] bg-no-repeat bg-contain -z-10 hidden md:block"
        style={{ backgroundImage: `url(${homePaw})` }}
      ></div>
      <div
        className="absolute right-0 top-[250px] w-[700px] h-[700px] bg-no-repeat bg-contain -z-10 hidden md:block"
        style={{ backgroundImage: `url(${homeBone})` }}
      ></div>

      <section className="h-screen overflow-hidden relative hidden md:block">
        <div className="h-full flex flex-col-reverse md:flex-row items-center relative">
          <div className="flex flex-col items-center md:items-start text-center md:text-left px-4 space-y-6 absolute inset-y-0 left-0 h-full justify-center">
            <img
              src={vanIcon}
              alt="Van Icon"
              className="h-[100px] hidden md:block md:mb-[342px] absolute"
            />
            <h1
              className=" text-4xl md:text-6xl font-semibold"
              style={{ lineHeight: '1.2' }}
            >
              Bringing the Best in <br />
              <span className="text-[#f0737b]">Pet Grooming</span> Right <br />
              to Your Door!
            </h1>
            <button
              onClick={() => (window.location.href = '/booknow')}
              className=" text-xl font-semibold bg-[#f0737b] text-white px-5 py-2 rounded-full cursor-pointer hover:scale-105 shadow-lg"
            >
              Book Now
            </button>

          </div>

          <div className=''>
            <img src={homeImg} alt="" className="md:ml-[400px]" />
          </div>
        </div>
      </section >

      <section className="block md:hidden">
        <div className="flex justify-center items-center relative">
          <img src={homePhone} alt="" className="absolute -z-10 h-[600px] w-auto mt-[600px]" />
        </div>

        <div className="flex flex-col items-center">
          <div className="flex flex-col items-center text-center justify-center px-4">
            <h1
              className="text-4xl font-semibold mb-4 mt-[350px]"
              style={{ lineHeight: '1.2' }}
            >
              Bringing the Best in <br />
              <span className="text-[#f0737b]">Pet Grooming</span> Right <br />
              to Your Door!
            </h1>
            <button
              onClick={() => (window.location.href = '/booknow')}
              className="text-lg font-semibold bg-[#f0737b] text-white px-5 py-2 rounded-full cursor-pointer hover:scale-105 shadow-lg"
            >
              Book Now
            </button>
          </div>
        </div>
      </section>

      <About />
    </div >
  );
};

export default Home;
