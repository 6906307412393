import React, { useState } from 'react';
import paw from '../Assets/images/paw.svg';

const Packages = () => {
    const [selectedPet, setSelectedPet] = useState('Toy Breed');

    const pricing = {
        'Toy Breed': {
            essential: 1000,
            comfort: 1250
        },
        'Adult Breed': {
            essential: 1150,
            comfort: 1500
        },
        'Cat': {
            essential: 1000,
            comfort: 1250
        }
    };

    const packages = {
        essential: {
            name: 'Essential Plan',
            features: [
                'Bath with Shampoo',
                'Blow dry',
                'Nail Clipping'
            ]
        },
        comfort: {
            name: 'Comfort Plan',
            features: [
                'Blow dry',
                'Bath with Shampoo & Conditioner',
                'Body & Mouth Splash',
                'Nail Clipping',
                'Combing & Brushing',
                'Ear & Eye Cleaning',
                'Teeth Cleaning',
                'Paw Massage & Trim',
                'Sanitary Trimming'
            ]
        }
    };

    const addOns = [
        { name: 'Fullbody Trim', price: 500 },
        { name: 'Fullbody Cut', price: 700 },
        { name: 'Medicated Bath', price: 200 }
    ];

    const handleToggle = (petType) => {
        setSelectedPet(petType);
    };

    return (
        <div className=''>
            <h1 className='font-bold text-2xl mb-4 text-center'>Choose your Package</h1>
            <div className="col-span-2 flex justify-center items-center">
                <div className="relative flex items-center bg-gray-400 rounded-full p-1 space-x-6 w-max">
                    <button
                        className={`toggle-button px-4 py-2 rounded-full ${selectedPet === 'Toy Breed' ? 'text-white bg-black' : 'text-white'}`}
                        onClick={() => handleToggle('Toy Breed')}
                    >
                        Toy Breed
                    </button>
                    <button
                        className={`toggle-button px-4 py-2 rounded-full ${selectedPet === 'Adult Breed' ? 'text-white bg-black' : 'text-white'}`}
                        onClick={() => handleToggle('Adult Breed')}
                    >
                        Adult Breed
                    </button>
                    <button
                        className={`toggle-button px-4 py-2 rounded-full ${selectedPet === 'Cat' ? 'text-white bg-black' : 'text-white'}`}
                        onClick={() => handleToggle('Cat')}
                    >
                        Cat
                    </button>
                </div>
            </div>

            <div className="mt-6 ">
                <div className='hidden md:block'>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Essential Package */}
                        <div>
                            <div className="package-card bg-[#83d3e8] flex flex-col justify-between p-6 rounded-lg shadow-md">
                                <div>
                                    <h3 className="text-xl font-bold ">{packages.essential.name}</h3>
                                    <ul className="mt-4 space-y-2">
                                        {packages.essential.features.map((feature, index) => (
                                            <li key={index} className="flex items-center text-md">
                                                <img
                                                    src={paw}
                                                    alt="paw"
                                                    className="w-4 h-4 mr-2"
                                                />
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <button className="text-lg font-semibold mt-4 bg-black text-white px-4 py-1 rounded-xl">
                                    ₹{pricing[selectedPet].essential}
                                </button>
                            </div>
                            <div className="bg-green-400 flex flex-col justify-between p-6 rounded-lg shadow-md mt-4">
                                <div>
                                    <h3 className="text-xl font-bold">Add-Ons</h3>
                                    <ul className="mt-4 space-y-2">
                                        {addOns.map((addOn, index) => (
                                            <li key={index} className="flex items-center justify-between text-md">
                                                <div className="flex items-center">
                                                    <img
                                                        src={paw}
                                                        alt="paw"
                                                        className="w-4 h-4 mr-2"
                                                    />
                                                    {addOn.name}
                                                </div>
                                                <span className="ml-4 bg-black text-white px-2  rounded-xl">₹{addOn.price}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Comfort Package */}
                        <div className="package-card bg-[#f0737b] flex flex-col justify-between glass-bg-2 p-6 rounded-lg shadow-md">
                            <div>
                                <h3 className="text-xl font-bold ">{packages.comfort.name}</h3>
                                <ul className="flex flex-wrap gap-2 ">
                                    {packages.comfort.features.map((feature, index) => (
                                        <li key={index} className="flex items-center text-md">
                                            <img
                                                src={paw}
                                                alt="paw"
                                                className="w-4 h-4 mr-2"
                                            />
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <button className="text-lg font-semibold mt-4 bg-black text-white rounded-xl px-4 py-1">
                                ₹{pricing[selectedPet].comfort}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='block md:hidden'>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Essential Package */}
                        <div>
                            <div className="package-card bg-[#83d3e8] flex flex-col justify-between p-6 rounded-lg shadow-md">
                                <div>
                                    <h3 className="text-xl font-bold ">{packages.essential.name}</h3>
                                    <ul className="mt-4 space-y-2">
                                        {packages.essential.features.map((feature, index) => (
                                            <li key={index} className="flex items-center text-md">
                                                <img
                                                    src={paw}
                                                    alt="paw"
                                                    className="w-4 h-4 mr-2"
                                                />
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <button className="text-lg font-semibold mt-4 bg-black text-white px-4 py-1 rounded-xl">
                                    ₹{pricing[selectedPet].essential}
                                </button>
                            </div>
                            
                        </div>

                        {/* Comfort Package */}
                        <div className="package-card bg-[#f0737b] flex flex-col justify-between glass-bg-2 p-6 rounded-lg shadow-md">
                            <div>
                                <h3 className="text-xl font-bold ">{packages.comfort.name}</h3>
                                <ul className="flex flex-wrap gap-2 ">
                                    {packages.comfort.features.map((feature, index) => (
                                        <li key={index} className="flex items-center text-md">
                                            <img
                                                src={paw}
                                                alt="paw"
                                                className="w-4 h-4 mr-2"
                                            />
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <button className="text-lg font-semibold mt-4 bg-black text-white rounded-xl px-4 py-1">
                                ₹{pricing[selectedPet].comfort}
                            </button>
                        </div>

                        <div className="bg-green-400 flex flex-col justify-between p-6 rounded-lg shadow-md ">
                                <div>
                                    <h3 className="text-xl font-bold">Add-Ons</h3>
                                    <ul className="mt-4 space-y-2">
                                        {addOns.map((addOn, index) => (
                                            <li key={index} className="flex items-center justify-between text-md">
                                                <div className="flex items-center">
                                                    <img
                                                        src={paw}
                                                        alt="paw"
                                                        className="w-4 h-4 mr-2"
                                                    />
                                                    {addOn.name}
                                                </div>
                                                <span className="ml-4 bg-black text-white px-2  rounded-xl">₹{addOn.price}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Packages;
