import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logoImg from '../Assets/images/tailsonwheels.png';

const Header = () => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      setIsScrolled(currentScrollY > 0);
      setIsHeaderVisible(currentScrollY < lastScrollY || currentScrollY === 0);

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);


  return (
    <header
      className={`fixed top-0 w-full z-40 transition-transform duration-300 ${isHeaderVisible ? 'translate-y-0' : '-translate-y-full'
        } ${isHomePage && !isScrolled
          ? 'bg-transparent'
          : 'bg-black/30 backdrop-blur-md shadow-md'
        }`}
    >
      <div className=" mx-auto flex items-center justify-between h-16">
        <a
          href="/"
          className={`flex items-center ${isHomePage && !isScrolled ? 'text-black' : 'text-white'
            } md:static absolute left-1/2 transform md:transform-none md:left-auto md:translate-x-0 -translate-x-1/2`}
        >
          <img
            src={logoImg}
            alt="Logo"
            className="h-14 md:h-20 px-6"
          />
        </a>

        <div
          className={`hidden md:flex items-center md:space-x-6 ${isHomePage && !isScrolled ? 'text-black' : 'text-white'
            }`}
        >
          <nav className="font-semibold text-base lg:text-lg">
            <ul className="flex items-center space-x-8">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/">About</a>
              </li>
            </ul>
          </nav>
          <button
            onClick={() => navigate('/booknow')}
            className="rounded-full font-semibold px-4 py-2 bg-[#f0737b] text-white"
          >
            Book Now
          </button>
        </div>
      </div>
    </header>

  );
};

export default Header;
