import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Packages from "./Packages";
import thankyouPet from '../Assets/thankyou.png';

const BookNow = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [formData, setFormData] = useState({
    addons: [],
    package: "",
  });

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "addons") {
      setFormData((prevState) => ({
        ...prevState,
        addons: checked
          ? [...prevState.addons, value]
          : prevState.addons.filter((addon) => addon !== value),
      }));
    } else if (name === "package") {
      setFormData((prevState) => ({
        ...prevState,
        package: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const finalFormData = {
      Name: e.target.name.value,
      Email: e.target.email.value,
      Phone: e.target.phone.value,
      Location: e.target.location.value,
      PetType: e.target.petType.value,
      PetBreed: e.target.petBreed.value,
      PetName: e.target.petName.value,
      PetAge: e.target.petAge.value,
      Package: formData.package,
      Addons: formData.addons.join(", "),
      AppointmentDate: appointmentDate  ? appointmentDate.toLocaleDateString("en-GB") : "",
      TimeSlot: e.target.timeSlot.value,
    };

    console.log("Submitting the following data to the sheet:", finalFormData);

    try {
      const response = await fetch("https://script.google.com/macros/s/AKfycbxPcU4_zKbl9q3pVqsaYNiYUi8R5j5m_4NclgB8eIfUmYIOd8zR-D9lxEIx93hf6wvYGA/exec",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(finalFormData),
        }
      );

      const result = await response.text();
      console.log(result);

      setIsSubmitted(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="pt-20 bg-slate-100">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="p-4">
          <Packages />
        </div>
        <div className="">
          <div className="p-4 rounded-r-xl flex flex-col justify-between">
            <h1 className="font-bold text-2xl mb-4 text-center">Book Now</h1>
            <div className="bg-white rounded-lg p-4">
              <div className="" aria-modal="true" role="dialog">
                <div
                  className="rounded-2xl"
                  onClick={(e) => e.stopPropagation()}
                  role="document"
                  aria-labelledby="modalTitle"
                  tabIndex={-1}
                >
                  {isSubmitted ? (
                    <div className="thank-you-message text-center  flex flex-col items-center">
                      <img src={thankyouPet} alt="" className="h-[300px] md:h-[450px]"/>
                      <h2 id="modalTitle" className="text-3xl font-bold mb-2">
                        Thank You!
                      </h2>
                      <p className="text-xl mb-4">
                        Our team will reach-out to you.
                      </p>
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit} className="">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <label className="block mb-2 font-semibold">
                          Name
                          <input
                            type="text"
                            name="name"
                            className="font-normal w-full mt-1 px-4 py-2 text-black rounded-lg border"
                            placeholder="Enter Your Name"
                            required
                          />
                        </label>
                        <label className="block font-semibold mb-2">
                          Phone
                          <input
                            type="tel"
                            name="phone"
                            className="font-normal w-full mt-1 px-4 py-2 text-black rounded-lg border"
                            placeholder="Enter Your Phone Number"
                            required
                          />
                        </label>

                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                        <label className="block font-semibold mb-2">
                          Email
                          <input
                            type="email"
                            name="email"
                            className="font-normal w-full mt-1 px-4 py-2 text-black rounded-lg border"
                            placeholder="Enter Your Email"
                            required
                          />
                        </label>
                        <label className="block mb-2 font-semibold">
                          Full Address
                          <input
                            type="text"
                            name="location"
                            className="font-normal w-full mt-1 px-4 py-2 text-black rounded-lg border"
                            placeholder="Enter Your Full Address"
                            required
                          />
                        </label>
                      </div>

                      <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <label className="block mb-2 font-semibold">
                            Choose Your Pet
                            <select
                              name="petType"
                              className="font-normal w-full mt-1 px-4 py-2 text-black rounded-lg border cursor-pointer"
                              defaultValue=''
                              required
                            >
                              <option value="" disabled>
                                Select the Pet Breed
                              </option>
                              <option value="Toy Breed">Toy Breed</option>
                              <option value="Adult Breed">Adult Breed</option>
                              <option value="Cat">Cat</option>
                            </select>
                          </label>
                        </div>

                        <div>
                          <label className="block mb-2 font-semibold">
                            Breed Category
                            <select
                              name="petBreed"
                              className="font-normal w-full mt-1 px-4 py-2 text-black rounded-lg border cursor-pointer"
                              defaultValue=''
                              required
                            >
                              <option value="" disabled>
                                Select the Breed
                              </option>
                              <option value="Labrador Retriever">Labrador Retriever</option>
                              <option value="German Shepherd">German Shepherd</option>
                              <option value="Golden Retriever">Golden Retriever</option>
                              <option value="Bulldog">Bulldog</option>
                              <option value="Beagle">Beagle</option>
                              <option value="Poodle">Poodle</option>
                              <option value="Rottweiler">Rottweiler</option>
                              <option value="Yorkshire Terrier">Yorkshire Terrier</option>
                              <option value="Boxer">Boxer</option>
                              <option value="Dachshund">Dachshund</option>
                            </select>
                          </label>
                        </div>
                      </div>

                      <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <h3 className="font-semibold mb-2">Package</h3>
                          <label className="flex gap-2">
                            <input
                              type="radio"
                              name="package"
                              value="Essential"
                              checked={formData.package === "Essential"}
                              onChange={handleChange}
                            />
                            Essential Package
                          </label>
                          <label className="flex gap-2">
                            <input
                              type="radio"
                              name="package"
                              value="Comfort"
                              checked={formData.package === "Comfort"}
                              onChange={handleChange}
                            />
                            Comfort Package
                          </label>
                        </div>

                        <div>
                          <h3 className="font-semibold mb-2">Add-ons (Optional)</h3>
                          <label className="flex gap-2">
                            <input
                              type="checkbox"
                              name="addons"
                              value="Fullbody Trim"
                              checked={formData.addons.includes("Fullbody Trim")}
                              onChange={handleChange}
                            />
                            Fullbody Trim
                          </label>
                          <label className="flex gap-2">
                            <input
                              type="checkbox"
                              name="addons"
                              value="Fullbody Cut"
                              checked={formData.addons.includes("Fullbody Cut")}
                              onChange={handleChange}
                            />
                            Fullbody Cut
                          </label>
                          <label className="flex gap-2">
                            <input
                              type="checkbox"
                              name="addons"
                              value="Medicated Bath"
                              checked={formData.addons.includes("Medicated Bath")}
                              onChange={handleChange}
                            />
                            Medicated Bath
                          </label>
                        </div>
                      </div>

                      <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <label className="block mb-2 font-semibold">
                          Pet Name
                          <input
                            type="text"
                            name="petName"
                            className="font-normal w-full mt-1 px-4 py-2 text-black rounded-lg border"
                            placeholder="Enter Your Pet's Name"
                            required
                          />
                        </label>
                        <label className="block mb-2 font-semibold">
                          Pet Age
                          <input
                            type="number"
                            name="petAge"
                            className="font-normal w-full mt-1 px-4 py-2 text-black rounded-lg border"
                            placeholder="Enter Your Pet's Age"
                            required
                          />
                        </label>
                      </div>

                      <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <label className="block mb-2 font-semibold">
                          Appointment Date
                          <DatePicker
                            selected={appointmentDate}
                            onChange={(date) => setAppointmentDate(date)}
                            className="font-normal w-full mt-1 px-4 py-2 text-black rounded-lg border cursor-pointer"
                            placeholderText="DD-MM-YYYY"
                            minDate={new Date()}
                          />
                        </label>

                        <label className="block mb-2 font-semibold">
                          Time Slot
                          <select
                            name="timeSlot"
                            className="font-normal w-full mt-1 px-4 py-2 rounded-lg border cursor-pointer"
                            required
                          >
                            <option value="" disabled>
                              Select a Time Slot
                            </option>
                            {[
                              "8:00 AM - 9:30 AM",
                              "10:00 AM - 11:30 AM",
                              "12:00 PM - 1:30 PM",
                              "2:00 PM - 3:30 PM",
                              "4:00 PM - 5:30 PM",
                              "6:00 PM - 7:30 PM",
                            ].map((slot) => (
                              <option key={slot} value={slot}>
                                {slot}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>

                      <button
                        type="submit"
                        className="w-full bg-[#f0737b] font-bold text-white py-2 rounded-lg"
                      >
                        Submit
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookNow;
