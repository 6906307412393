import React from "react";
import step1 from '../Assets/scheduling.gif';
import step2 from '../Assets/call.gif';
import step3 from '../Assets/tracking.gif';
import step4 from '../Assets/arrival.gif';
import step5 from '../Assets/payment.gif';
import blobImg from "../Assets/images/blob-3.png";

const BookingProcess = () => {
  return (
    <section className="">
      <div
        className="absolute top-[2100px] -left-36 w-[500px] h-[500px] bg-no-repeat bg-cover -z-10 hidden md:block"
        style={{ backgroundImage: `url(${blobImg})` }}
      ></div>
      <main className="">
        
        <div className="hidden md:block">
        <h1 className="text-center text-3xl font-bold text-[#f0737b] mb-8">
          How it works?
        </h1>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <div className="grid grid-cols-5 gap-10 items-center justify-center max-w-[1350px] mx-auto">

                <div>
                  <div className="">
                    <h3 className="text-xl font-bold mb-2">1.Select the Schedule</h3>
                    <p className="text-gray-600 text-md">
                      Choose a convenient time for your booking
                    </p>
                  </div>
                </div>

                <div>
                  <div className="">
                    <img
                      src={step2}
                      alt="Confirmation Call"
                      className="w-[200px] h-[180px]"
                    />
                  </div>
                </div>

                <div>
                  <div className="">
                    <h3 className="text-xl font-bold mb-2">3.Track the Van</h3>
                    <p className="text-gray-600 text-md">
                      Track our van starting 1 hour before your slot
                    </p>
                  </div>
                </div>

                <div>
                  <div className="">
                    <img
                      src={step4}
                      alt="Van Arrives"
                      className="w-[200px] h-[200px] -mt-4"
                    />
                  </div>
                </div>

                <div>
                  <div className="">
                    <h3 className="text-xl font-bold mb-2">5.Pay & Feedback</h3>
                    <p className="text-gray-600 text-md">
                      Make payment post-session and share your feedback
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div className="bg-black h-[80px] w-full flex items-center justify-center space-x-9">
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                  <div className="bg-yellow-300 h-[6px] w-[20px]"></div>
                </div>
              </div>

              <div>
                <div className="grid grid-cols-5 gap-10 items-center justify-center max-w-[1350px] mx-auto">
                  <div>
                    <div className="">
                      <img
                        src={step1}
                        alt="Select the Schedule"
                        className="w-[200px] h-[200px] "
                      />
                    </div>
                  </div>

                  <div>
                    <div className="">
                      <div className="">
                        <h3 className="text-xl font-bold mb-2">2.Confirmation Call</h3>
                        <p className="text-gray-600 text-md">
                          We will call you to confirm your booking
                        </p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="">
                      <img
                        src={step3}
                        alt="Track the Van"
                        className="w-[200px] h-[200px] scale-105"
                      />
                    </div>
                  </div>

                  <div>
                    <div className="">
                      <h3 className="text-xl font-bold mb-2">4.Van Arrives</h3>
                      <p className="text-gray-600 text-md">
                        Our grooming van arrives at your doorstep
                      </p>
                    </div>
                  </div>

                  <div>
                    <div className="">
                      <img
                        src={step5}
                        alt="Pay & Feedback"
                        className="h-[200px] scale-75"
                      />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* MOBILE VERSION */}
        <div className="block md:hidden mt-4">
        <h1 className="text-center text-3xl font-bold text-[#f0737b]">
          How it works?
        </h1>
          <div className="text-center flex flex-col gap-4 items-center justify-center">
            <div className="flex flex-col justify-center items-center">
              <img
                src={step1}
                alt="Select the Schedule"
                className="w-[200px] h-[180px] object-cover"
              />
              <h3 className="text-xl font-bold">1.Select the Schedule</h3>
              <p className="text-gray-600 text-md">
                Choose a convenient time for your booking
              </p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <img
                src={step2}
                alt="Confirmation Call"
                className="w-[200px] h-[180px] object-cover"
              />
              <h3 className="text-xl font-bold ">2.Confirmation Call</h3>
              <p className="text-gray-600 text-md">
                We will call you to confirm your booking
              </p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <img
                src={step3}
                alt="Track the Van"
                className="w-[200px] h-[200px] object-cover"
              />
              <h3 className="text-xl font-bold">3.Track the Van</h3>
              <p className="text-gray-600 text-md">
                Track our van starting 1 hour before your slot
              </p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <img
                src={step4}
                alt="Van Arrives"
                className="w-[200px] h-[200px] object-cover"
              />
              <h3 className="text-xl font-bold ">4.Van Arrives</h3>
              <p className="text-gray-600 text-md">
                Our grooming van arrives at your doorstep
              </p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <img
                src={step5}
                alt="Pay & Feedback"
                className="h-[200px] object-cover"
              />
              <h3 className="text-xl font-bold">5.Pay & Feedback</h3>
              <p className="text-gray-600 text-md">
                Make payment post-session and share your feedback
              </p>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
};

export default BookingProcess;
