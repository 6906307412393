import React, { useState, useEffect } from "react";
import blobImg from "../Assets/images/blob-3.png";
import customer1Img from "../Assets/images/customer-1.png";
import customer2Img from "../Assets/images/customer-2.jpg";
import customer3Img from "../Assets/images/customer-3.jpg";
import customer4Img from "../Assets/images/customer-4.jpg";
import customer5Img from "../Assets/images/customer-5.jpg";
import pawImg from "../Assets/images/paw-quotes.png";
import CTA from "../Components/CTA";

const testimonialsData = [
  {
    image: customer1Img,
    text: "Perfect for Busy People! Tails on Wheels makes life so easy. They come to my door and take care of everything. My dog loves them.",
    name: "Jaya",
  },
  {
    image: customer2Img,
    text: "Amazing service! My cat has never looked so clean and happy. I recommend them to all my friends with pets!",
    name: "Revathi",
  },
  {
    image: customer3Img,
    text: "The convenience is unmatched! The team is professional and my dog absolutely adores them. Thank you!",
    name: "Riya",
  },
  {
    image: customer4Img,
    text: "I can't imagine going back to the old way of pet grooming. Tails on Wheels is a game changer!",
    name: "Vignesh",
  },
  {
    image: customer5Img,
    text: "So happy I found this service. My dog gets pampered, and I don't have to do a thing!",
    name: "Kani",
  },
  {
    image: customer3Img,
    text: "Highly professional and super friendly. My pet is in love with Tails on Wheels!",
    name: "Priya",
  },
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [reverseIndex, setReverseIndex] = useState(testimonialsData.length - 1);

  useEffect(() => {
    // Preload images
    testimonialsData.forEach(({ image }) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
      setReverseIndex(
        (prevIndex) => (prevIndex - 1 + testimonialsData.length) % testimonialsData.length
      );
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const renderTestimonial = (index) => {
    const { image, text, name } = testimonialsData[index];
    return (
      <div className="flex flex-col md:flex-row justify-between items-center gap-4">
        <div>
          <img
            src={image}
            alt={name}
            className="team-profile-blob w-24 h-24 rounded-full object-cover transition duration-500"
          />
        </div>
        <div className="flex-1 text-center md:text-left">
          <p className="text-gray-700 text-lg mb-4">"{text}"</p>
          <p className="font-semibold text-gray-800">- {name}</p>
        </div>
        <div className="hidden md:block">
          <img src={pawImg} alt="Quotes" className="w-20 -mt-12 mr-8" />
        </div>
      </div>
    );
  };

  const renderTestimonial2 = (index) => {
    const { image, text, name } = testimonialsData[index];
    return (
      <div className="flex flex-col md:flex-row-reverse justify-between items-center gap-4">
        <div className="order-1 flex items-center justify-center">
          <img
            src={image}
            alt={name}
            className="team-profile-blob w-24 h-24 rounded-full object-cover transition duration-500"
          />
        </div>
        <div className="flex-1 text-center md:text-right order-2">
          <p className="text-gray-700 text-lg mb-4">"{text}"</p>
          <p className="font-semibold text-gray-800">- {name}</p>
        </div>
        <div className="order-2">
          <img
            src={pawImg}
            alt="Quotes"
            className="w-20 -mt-12 hidden md:block"
          />
        </div>
      </div>
    );
  };

  return (
    <section className="relative h-screen">
      <main className="">
        <div
          className="absolute bottom-52 -left-[220px] w-[500px] h-[500px] bg-no-repeat bg-cover -z-10 hidden md:block"
          style={{ backgroundImage: `url(${blobImg})` }}
        ></div>

        <div className="py-8 flex flex-col justify-center items-center">
          <h1 className="text-3xl font-bold text-[#f0737b] mb-8 text-center">
            Check what Our Pet Parents say..
          </h1>

          <div className="w-full max-w-[1000px] mx-auto mb-6">
            {renderTestimonial(currentIndex)}
          </div>

          <div className="w-full max-w-[1000px] mx-auto">
            {renderTestimonial2(reverseIndex)}
          </div>
        </div>
      </main>
      <main className="-mt-10">
        <CTA />
      </main>
    </section>
  );
};

export default Testimonials;