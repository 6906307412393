import React from 'react';
import ctaImg from '../Assets/images/contact.png';
import Footer from '../Components/Footer';

const CTA = () => {
    return (
        <section>
            <main className="max-w-[1240px] mx-auto relative pt-12 mb-2 p-2">
            <div className='z-10 flex flex-col justify-center '>
                <img
                    src={ctaImg}
                    alt="Pets peeping"
                    className="w-full max-w-[500px] mx-auto h-full scale-105 md:scale-125 "
                />
            </div>
            <div className='mt-4 md:mt-0'>
            <div className="w-full md:max-w-[1000px] mx-auto p-2 md:p-4 bg-[#f0737b] flex flex-col gap-4 rounded-lg shadow-xl text-center sm:text-left -mt-[51px] md:-mt-[40px]">
                <div className='pt-6'>
                    <h1 className="text-xl sm:text-2xl font-semibold mb-2 text-black">
                        Book your pet's next grooming appointment with us today!
                    </h1>
                    <p className="text-base sm:text-lg text-white">
                        Experience the difference of mobile grooming in Van with us – a stress-free, convenient, and personalized approach to pet grooming.
                    </p>
                </div>
                <div className="flex justify-center sm:justify-end">
                    <button
                        onClick={() => (window.location.href = '/booknow')}
                        className="text-white rounded-full font-semibold px-6 py-3 bg-[#83d3e7] shadow-lg hover:bg-[#6bbcd5] transition-colors"
                    >
                        Book Now
                    </button>
                </div>
            </div>
            </div>
        </main>

        <Footer/>
        </section>
    );
};

export default CTA;
