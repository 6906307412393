import React from 'react';
import abtImg from '../Assets/images/abt-img.png';
import abtImg2 from '../Assets/images/abt-img-2.png'
import abtMobImg from '../Assets/images/TailsonWheels-Van.png';

const About = () => {
  return (
    <section className="" >
      {/* <div
        className="absolute -bottom-28 -right-40 w-[500px] h-[500px] bg-no-repeat bg-contain hidden md:block"
        style={{ backgroundImage: `url(${abtBlob})` }}
      ></div> */}

      <main className='h-screen relative hidden md:block' id="about">
        <div className="max-w-[1240px] mx-auto py-32">
          <div className="flex justify-between gap-2">
            <div className='text-center md:text-left'>
              <h1 className="text-3xl md:text-4xl font-bold text-[#f0737b] mb-0 md:mb-10">About Us</h1>
              <img
                src={abtMobImg}
                alt="Pet Grooming"
                className="md:hidden block" />
              <p className="text-md md:text-lg w-full md:w-[620px] md:p-0 " style={{ lineHeight: '1.8' }}>
                Our mobile van pet grooming service delivers the entire salon
                experience right to your house front. Our professional groomers
                arrive equipped with everything needed to provide your pet with
                top-quality grooming, all in the comfort of your home.
              </p>
              <img src={abtImg2} alt="" className='h-[400px] -mt-20 hidden md:block' />
            </div>
            <img
              src={abtImg}
              alt="Pet Grooming"
              className="h-[440px] scale-[2] mr-[120px] hidden md:block"
            />
          </div>
        </div>
      </main>

      <main className="pt-16 block md:hidden">
        <div className='flex flex-col justify-center items-center'>
          <h1 className="text-3xl font-bold text-[#f0737b]">About Us</h1>

          <img
            src={abtMobImg}
            alt="Pet Grooming"
            className="md:hidden block h-[400px] -mt-8" />

          <p className="text-md w-full -mt-12 text-center m-4">
            Our mobile van pet grooming service delivers the entire salon
            experience right to your house front. Our professional groomers
            arrive equipped with everything needed to provide your pet with
            top-quality grooming, all in the comfort of your home.
          </p>
        </div>
      </main>

    </section>
  );
};

export default About;
