import React from 'react';
import img1 from '../Assets/images/why-us/img-1.png';
import img2 from '../Assets/images/why-us/img-2.png';
import img3 from '../Assets/images/why-us/img-3.png';
import img4 from '../Assets/images/why-us/img-4.png';
import Testimonials from './Testimonials';

const WhyUs = () => {
    return (
        <div className='h-screen py-4'>
            <main className=''>
                <div className='mt-2'>
                    <h1 className='text-center text-3xl font-bold text-[#f0737b]'>Why Us?</h1>
                </div>
                <section className="m-5 grid grid-cols-1 gap-3 md:grid-cols-4 text-black py-0 md:py-12">
                    {/* Card 1 */}
                    <div className="bg-[#E3F9FE] rounded-xl p-4 flex flex-col justify-between md:row-span-2">
                        <h1 className="text-xl md:text-lg text-center md:text-left font-semibold mb-2">Hassle-free grooming</h1>
                        <img src={img1} alt="Hassle-free grooming" className="h-[200px] md:h-[300px] object-contain" />
                        <p className="text-black text-center md:text-left">
                            Experience hassle-free pet grooming with our professional, caring team—because your furry friend deserves the best. Convenient, stress-free, and tailored to your pet's needs!
                        </p>
                    </div>

                    {/* Card 2 */}
                    <div className="bg-[#E6E9FD] rounded-xl p-4 flex flex-col justify-center items-center md:items-start">
                        <h1 className="text-center md:text-left text-xl md:text-lg font-semibold mb-3">Convenience at your ease</h1>
                        <p className="text-black text-center md:text-left">
                            We bring care and style right to your doorstep, making pet grooming stress-free and easy!
                        </p>
                        <img
                            src={img4}
                            alt="Convenience"
                            className="h-[200px] md:h-[180px] md:scale-125 md:scale-x-[-1.5] md:ml-10"
                        />
                    </div>

                    {/* Card 3 (Standalone Image) */}
                    <div className="hidden md:block">
                        <img src={img2} alt="Grooming" className="scale-110" />
                    </div>

                    {/* Card 4 */}
                    <div className="bg-[#FDF3C9] rounded-xl p-4 flex flex-col justify-center items-center md:items-start">
                        <h1 className="text-xl md:text-lg font-semibold mb-3">Stress-free</h1>
                        <p className="text-black text-center md:text-left">
                            Experience a stress-free grooming process designed for your furry friends and their loving parents. We ensure comfort, care, and convenience for happy paws and peaceful hearts!
                        </p>
                    </div>

                    {/* Card 5 */}
                    <div className="bg-[#FBEDEC] rounded-xl p-4 flex flex-col justify-center items-center md:flex-row md:col-span-2">
                        <div className="md:w-[65%] text-center md:text-left">
                            <h1 className="text-xl md:text-lg font-semibold mb-3">Flexible scheduling</h1>
                            <p>
                                Keep your pets looking their best with our flexible grooming services tailored to your busy lifestyle. Book at your convenience and let us pamper your furry friend!
                            </p>
                        </div>
                        <div className="mt-4 md:mt-0">
                            <img src={img3} alt="Flexible scheduling" className="h-[210px] scale-125" />
                        </div>
                    </div>

                    {/* Card 6 */}
                    <div className="bg-[#EAFCE2] rounded-xl p-4 flex flex-col justify-center items-center md:items-start">
                        <h1 className="text-xl md:text-lg font-semibold mb-3">Transparent Pricing</h1>
                        <p className="text-black text-center md:text-left">
                            Pamper your pets with top-notch grooming services at prices you can trust. With our transparent pricing, you'll always know exactly what you're paying for—no hidden fees, just happy pets!
                        </p>
                    </div>
                </section>

            </main>
            <div className=''>
            <Testimonials/>
            </div>
        </div>
    )
}

export default WhyUs





