import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Components/Header';
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import BookingProcess from './Components/BookingProcess';
import WhyUs from './Components/WhyUs';
import BookNow from './Components/BookNow';
import FloatingButton from './Components/FloatingButton';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <Header />
              <Home />
              <BookingProcess />
              <WhyUs />
              <FloatingButton/>
            </div>
          }
        />
        
        <Route
          path="/booknow"
          element={
            <div>
              <Header />
              <BookNow />
              <Footer />
            </div>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
