import React from 'react';
import footerBg from '../Assets/images/footer.png';
import paw from '../Assets/images/paw.svg';
import facebook from '../Assets/logos/facebook.png';
import instagram from '../Assets/logos/instagram.png';
import whatsapp from '../Assets/logos/whatsapp.png';

const Footer = () => {
    return (
        <footer
            className="h-full md:h-[500px] flex flex-col relative bg-[#83d3e7] md:bg-transparent"
        >
            <div
                className="absolute inset-0 hidden md:block"
                style={{
                    backgroundImage: `url(${footerBg})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}
            ></div>

            <section className="max-w-[1340px] mx-auto relative z-10">
                <div className="py-2 md:py-12 md:mt-32 p-4">
                    <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
                        <div className="col-span-2">
                            <p className="text-md">
                                At Tails on Wheels, we take pride in offering a convenient and stress-free grooming
                                experience for your beloved pets. Our mobile grooming studio in Van is designed to
                                cater to your furry friend's needs with the utmost care and professionalism.
                            </p>
                        </div>

                        <div>
                            <h1 className="font-bold text-sm md:text-md mb-2">Support</h1>
                            <ul className="text-sm md:text-md flex flex-col gap-2 list-none">
                                <li className="flex items-center gap-2">
                                    <img src={paw} alt="paw" className="w-4 h-4" />
                                    FAQ
                                </li>
                                <li className="flex items-center gap-2">
                                    <img src={paw} alt="paw" className="w-4 h-4" />
                                    Privacy Policy
                                </li>
                                <li className="flex items-center gap-2">
                                    <img src={paw} alt="paw" className="w-4 h-4" />
                                    Help
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h1 className="font-bold text-sm md:text-md mb-2">Contact Us</h1>
                            <ul className="text-sm md:text-md flex flex-col gap-2 list-none">
                                <li className="flex items-center gap-2">
                                    <img src={paw} alt="paw" className="w-4 h-4" />
                                    +91 701 060 3063
                                </li>
                                <li className="flex items-center gap-2">
                                    <img src={paw} alt="paw" className="w-5 h-4" />
                                    official@tailsonwheelscbe.com
                                </li>
                            </ul>
                            <div className="flex gap-4 mt-4">
                                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={instagram}
                                        alt="Instagram"
                                        className="w-6 h-6 hover:scale-110 transition-transform"
                                    />
                                </a>
                                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={facebook}
                                        alt="Facebook"
                                        className="w-6 h-6 hover:scale-110 transition-transform"
                                    />
                                </a>
                                <a href="https://wa.me/917010603063" target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={whatsapp}
                                        alt="WhatsApp"
                                        className="w-6 h-6 hover:scale-110 transition-transform"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className='hidden md:block'>

                        </div>
                        <div className="col-span-2 border-t border-blue-950 mt-2 md:mt-5">
                            <p className='text-center md:text-left text-sm md:text-md'>
                                Tails On Wheels. All Rights Reserved. Designed & Developed by{' '}
                                <a href="https://namuvi.com/" className="text-[#176779] underline">
                                    Namuvi Technologies
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>


        </footer>
    );
};

export default Footer;
