import React, { useEffect, useState } from 'react';

const FloatingButton = () => {
  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const isFooterVisible = entries[0].isIntersecting;
        setShowButton(!isFooterVisible);
      },
      { threshold: 0.1 }
    );

    const footerElement = document.querySelector('footer');
    if (footerElement) {
      observer.observe(footerElement);
    }

    return () => {
      if (footerElement) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    showButton && (
      <div className="fixed bottom-4 right-4 z-[999]">
        <button
          className="bg-[#f0737b] text-white text-sm md:text-md font-semibold py-2 px-4 rounded-full shadow-lg hover:bg-[#d65860] transition duration-300"
          onClick={() => (window.location.href = '/booknow')}
        >
          <span className="hidden sm:inline">Book Now</span>
          <span className="sm:hidden">Book</span>
        </button>
      </div>
    )
  );
};

export default FloatingButton;
